// --------------------------------------------------------------
//  TABS
// --------------------------------------------------------------

.c-navigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(0);
    background-color: #a5abbd;
    transition-property: transform, visibility, width;
    width: 470px;
    right: auto;
    transition: transform 200ms 0s;
    padding: 150px 50px;
    z-index: 150;

    &--hidden {
        transform: translateX(-470px);
        visibility: hidden;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 25px;

        li {
            margin-bottom: 20px;

            a {
                color: #fff;
            }
        }
    }
}
