@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');
@import '../../node_modules/bootstrap/scss/bootstrap';

@import 'variables';

@import 'components/navigation';

body {
    margin: 0;
    font-family: 'Source Sans Pro', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f9f9f9;
}

.c-header {
    position: relative;
    z-index: 999;

    &__inner {
        width: 100%;
        height: 60px;
        background: rgb(0,97,178);
        background: linear-gradient(90deg, #0061b2 0%, #009ad9 100%);
        color: white;
        box-sizing: border-box;
        line-height: 1;
        padding: 15px;
    }
}

.c-header__user-text {
    font-size: 28px;
}

.c-header__location-text {
    margin-left: 10px;
}

.bold {
    font-weight: bold;
}

.loader {
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.logout {
    height: 25px;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid white;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100dvh;
}

.ant-descriptions-item-label,
.ant-card-head-title {
    font-weight: bold;
    text-transform: uppercase;
}

.tox.tox-silver-sink.tox-tinymce-aux {
    display: none;
}

.o-chat-wrapper {
    position: absolute;
    bottom: 0;
    right: 3rem;
}

.c-chat-item {
    width: 350px;
    height: 300px;
    box-shadow: 0px 10px 13px gray;

    .ant-card-body {
        height: 205px;
    }

    &__list {
        list-style: none;
        padding: 0;
        overflow-y: auto;
        max-height: 100%;
    }
}

.e-chat {
    display: inline-block;
    padding: 0.5rem;
    color: white;
    border-radius: 5px;
    max-width: 70%;
    margin-bottom: 10px;

    &__me {
        background: #0061b2;
        float: right;
    }

    &__you {
        background: #009ad9;
        float: left;
    }
}

.auftrag-uebersicht {
    width: 350px;
    position: absolute;
    z-index: 100;
    left: 15px;
    bottom: 0;
    background: white;
    box-shadow: 0 0 10px gray;
    border-radius: 10px 10px 0 0;
    height: calc(100% - 20px);
}

.anrufe-uebersicht {
    width: calc(100vw - 700px);
    min-width: 400px;
    position: absolute;
    z-index: 100;
    right: 15px;
    bottom: 15px;
    background: white;
    box-shadow: 0 0 10px gray;
    border-radius: 10px;
}

.suche-strasse {
    @extend .anrufe-uebersicht;
    width: calc(100vw - 440px);
    overflow: hidden;
    top: 20px;
    padding: 5px;
    bottom: auto;
    right: 55px;
}

.border-card {
    border-radius: 20px;
    overflow: hidden;

    &--bordered {
        border: 2px solid #e3f0ff;
    }

    &--shadow {
        box-shadow: #000d20 0 0 10px;
    }
}

.map-page {
    background: #414756;
    color: #e3f0ff;
}

.switchForm_map {
    background: rgba(#f5f9ff, 0.7);
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #414756;
}

.signPad {
    position: relative;

    &:after {
        content: '';
        height: 2px;
        background: lightgrey;
        width: 80%;
        bottom: 70px;
        left: 10%;
        position: absolute;
    }
}